<template>
  <div>
    <Header></Header>
    <!-- 大图 -->
    <!-- <div class="dt">
      <div class="fs42 pt126 white">专注于中小零售门店服务</div>
    </div> -->

    <!-- 关于我们 -->
    <div class="line-title">关于我们</div>
    <div class="about_us ">
      <div class="about_us_text">
        <div class="introduce">
          <p >
            彼思（惠州）科技有限公司成立于2019年，是一家主营企业管理软件开发的公司，多年来坚持“让所有企业都能使用上符合自身需求的管理系统”的企业理念。来为用户提供优质的产品和服务，目前公司旗下主要产品为彼思生产ERP和彼思零售ERP。
          </p>
          <p>
            彼思生产ERP软件适用于制造生产行业，包含了库存、采购、销售、生产、财务、人事等多种功能，可以使企业实现标准化规范化，提高企业的生产管理效率。
          </p>
        </div>
      </div>
    </div>

 <div class="line-title">企业文化</div>

  <div class="wh">
    <div class="wh-left">
      <div class="wh-left-text">使命</div>
      <div class="wh-left-subtext">让所有企业都能使用上符合自身需求的管理系统</div>

      <div class="wh-left-text">愿景</div>
      <div class="wh-left-subtext">成为一家可以“高效提高客户管理效率”的公司，与客户实现合作共赢</div>

      <div class="wh-left-text">价值观</div>
      <div class="wh-left-subtext">客户至上：以客户的需求为出发点，不断完善产品</div>
      <div class="wh-left-subtext">企业创新：接收新技术、勇于突破，精益求精</div>
      <div class="wh-left-subtext">合作共赢：合作互利，共同发展。</div>
    </div>
    <div class="wh-right">
        <img src="../assets/logo_new2.png" alt="">
    </div>

  </div>


    <!-- 联系我们 -->
    <div class="line-title pb30">联系我们</div>
    <div class="lxwm">
      <div class="lxwm_text">
        <div class="dz white">
          <div class="fs20 pb30">惠州彼思科技有限公司</div>
          <div class="line"></div>
          <p>公司地址：惠州市惠澳大道翠竹三路1号中骏柏景湾花园9号楼2单元5层04号房 </p>
          <p>在线客服：18122828713(微信)</p>
          <p>公司邮箱：626458890@qq.com</p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "about_us",
  data() {
    return {};
  },
  components: { Header, Footer },
};
</script>

<style scoped>
/* 大图 */
.dt {
  background-image: url(http://www.zhonglunnet.com/templets/zlnet/images/aboutbanner.jpg);
  background-position: top center;
  background-repeat: no-repeat;
  height: 522px;
}
/* 关于我们 */
.about_us {
  /* height: 330px; */
  margin-bottom: 50px;

}
.about_us_text {
  margin: 0 auto;
  width: 1100px;
}
.introduce {
  /* padding: 40px 50px; */
  line-height: 34px;
  font-size: 16px;
  background-color: #ffffff;
  width: 1100px;
  /* box-shadow: 0 0 10px #eee; */
  text-align: left;
  text-indent: 32px;
}
.qywh {
  margin-left: 120px;
}
.qywh table tr td {
  width: 480px;
  text-align: left;
}
.qywh table tr td img {
  margin-right: 40px;
  vertical-align: middle;
}

/* 联系我们 */
.lxwm {
  background-image: url(../assets/picture/addr.png);
  background-position: top center;
  background-repeat: no-repeat;
  width: 100%;
  height: 380px;
  /* background-size: auto 100%; */
}
.lxwm_text {
  width: 1100px;
  margin: 0 auto;
  text-align: left;
}
.dz {
  position: relative;
  top: 50px;
  left: 500px;
  width: 450px;
  padding: 30px 0px 30px 30px;
  background-color: #1b1919;
  opacity: 0.8;
}
.line {
  border-top: 1px solid #ffffff;
  width: 350px;
}


.line-title{
  font-size: 30px;
  font-weight: 700;
}

.wh{
  width: 1100px;
  margin: 0 auto;
  /* background-color: #1b1919; */
  display: flex;
}
.wh-left{
  width: 800px;
  text-align: left;
}
.wh-left-text{
  font-weight: 700;
  font-size: 25px;
  margin: 40px 0px 10px 0px;
}
.wh-left-subtext{
  font-size: 18px;
  margin: 10px 0px;
}

.wh-right{
  margin-top: 100px;
  width: 350px;
}

.wh-right img{
  width: 350px;
  height: 350px;
}


</style>